import {
  TrackEventPayload,
  TrackEventProperties
} from '@daedalus/core/src/analytics/types/Events'

export const getEventProperties = (
  trackEventPayload: TrackEventPayload
): TrackEventProperties & {name: string} => {
  const decoratedTrackEventPayload = {
    ...trackEventPayload
  }

  const eventProps = {
    ...decoratedTrackEventPayload,
    name: `${trackEventPayload.category}_${trackEventPayload.entity}_${trackEventPayload.action}`
  }

  return eventProps
}
