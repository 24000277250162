/* eslint-disable fp/no-mutation */
import {createSlice} from '@reduxjs/toolkit'
import {MetaState} from 'types/State'
import {getUrlParams} from 'utils/urlParams'

import {getBrandCodeFromPartnerKey} from '@daedalus/core/src/brand/utils'

const {partnerKey} = getUrlParams() as {partnerKey: string}

export const initialState: Pick<MetaState, 'anonymousId' | 'brandCode'> = {
  anonymousId: undefined,
  brandCode: getBrandCodeFromPartnerKey(partnerKey)
}

const {actions, reducer} = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setBrandCode: (state, {payload}) => {
      state.brandCode = payload
    }
  }
})

export const {setBrandCode} = actions

export default reducer
