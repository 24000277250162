import {Middleware} from 'redux'
import {partnersApi} from 'services/partnersApi'

import analytics from './sideEffects/analytics'
import partnersApiLogs from './sideEffects/partnersApiLogs'

export type MiddlewareType = Middleware

const middlewares = [partnersApi.middleware, partnersApiLogs, analytics]

export default middlewares
