// @ts-expect-error
import {v4 as uuid} from 'uuid'

import {useAddFeatureFlagTracking} from '@daedalus/core/src/analytics/hooks/useAddFeatureFlagTracking'
import {useOpticks} from '@daedalus/core/src/experiments/components/OpticksProvider'

type PropsType = {
  // eslint-disable-next-line no-undef
  children: React.ReactElement
}

export const InitializeABTesting = ({children}: PropsType) => {
  const Opticks = useOpticks()

  useAddFeatureFlagTracking()

  if (Opticks) {
    Opticks.setUserId(uuid())

    return children
  }

  return null
}
