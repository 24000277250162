/**
 * Capitalize first letter
 *
 * @param string - String you'd like to capitalize the first letter
 *
 * @returns String with the first letter capitalized
 */
export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

/**
 * Converts kebab-case string to camelCase
 *
 * @param string - string in kebab-case format
 *
 * @returns string in camelCase format
 *
 * @example
 * kebabCaseToCamelCase('kebab-case') // 'kebabCase'
 * kebabCaseToCamelCase('kebab-case-string') // 'kebabCaseString'
 */
export const kebabCaseToCamelCase = (string: string) =>
  string.replace(/(-\w)/g, m => m[1].toUpperCase())

/**
 * Splits the provided string at the last occurrence of the provided delimiter
 * and returns the two parts as an array.
 * If the delimiter is not found, the original string is returned as the first element
 * If the provided string is falsy, an empty array is returned.
 *
 * @param string - The string to split.
 * @param delimiter - The delimiter to split the string at.
 * @returns An array containing the two parts of the split string.
 */
export const splitAtLastDelimiter = (
  string: string | undefined,
  delimiter: string
): string[] => {
  if (!string) return []

  const indexOfLastDelimiter = string.lastIndexOf(delimiter)

  if (indexOfLastDelimiter === -1) {
    return [string]
  }

  const firstPart = string.slice(0, indexOfLastDelimiter)
  const secondPart = string.slice(
    indexOfLastDelimiter + delimiter.length,
    string.length
  )

  return [firstPart, secondPart]
}

/**
 * Generates a slug from the given value.
 *
 * @param value - The value to be converted to a slug.
 * @returns A slugified version of the input string.
 * @example
 * // Returns 'hesperia-barcelona-barri-gotic'
 * generateSlug('Hesperia Barcelona Barri Gòtic');
 */
export const generateSlug = (value: string): string => {
  return value
    .toLowerCase()
    .normalize('NFD') // Normalize accented characters
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
    .replace(/[^0-9a-z\s-]/g, '') // Remove special characters
    .trim() // Trim whitespace from the start and end
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
    .slice(0, 50) // Limit the length to 50 characters
    .replace(/^-|-$/g, '') // Remove leading or trailing hyphens
}

/**
 * Truncates a sentence to the last space before the provided max length and appends '...' to the end.
 * @param sentence - The sentence to truncate
 * @param maxLength - The maximum length of the truncated sentence
 * @returns The truncated sentence
 */
export const truncateSentence = (sentence: string, maxLength: number) => {
  if (sentence.length <= maxLength) {
    return sentence
  }

  const truncatedSentence = sentence.slice(0, Math.max(maxLength - 3, 0))
  const lastSpaceIndex = truncatedSentence.lastIndexOf(' ')

  const truncatedToLastSpace =
    lastSpaceIndex > -1
      ? truncatedSentence.slice(0, lastSpaceIndex)
      : truncatedSentence

  return truncatedToLastSpace + '...'
}
