import {isRejectedWithValue} from '@reduxjs/toolkit'
import {MiddlewareType} from 'middlewares'
import logPartnersService, {
  PartnersServiceEndpointNames,
  PartnersServiceStatus
} from 'services/logPartnersService'

import {partnersApi} from '../../services/partnersApi'

const middleware: MiddlewareType = () => next => async action => {
  if (partnersApi.endpoints.searchHotels.matchPending(action)) {
    const requestPayload = action?.meta?.arg?.originalArgs?.payload

    logPartnersService(
      PartnersServiceEndpointNames.SearchHotel,
      PartnersServiceStatus.start,
      requestPayload,
      'info'
    )
  }

  if (partnersApi.endpoints.searchHotels.matchFulfilled(action)) {
    const requestPayload = action?.meta?.arg?.originalArgs?.payload

    logPartnersService(
      PartnersServiceEndpointNames.SearchHotel,
      PartnersServiceStatus.done,
      requestPayload,
      'info'
    )
  }

  if (
    partnersApi.endpoints.searchHotels.matchRejected(action) &&
    isRejectedWithValue(action)
  ) {
    const errorPayload = action?.payload

    logPartnersService(
      PartnersServiceEndpointNames.SearchHotel,
      PartnersServiceStatus.error,
      errorPayload,
      'error'
    )
  }

  return next(action)
}

export default middleware
