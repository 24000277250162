import {pickAll} from 'ramda'

const configKeys = [
  'REACT_APP_ENV',
  'REACT_APP_VERSION',
  'PUBLIC_URL',
  'REACT_APP_IMGPROXY_ENDPOINT_URL',
  'REACT_APP_IMGPROXY_ENDPOINT_SECRET',
  'REACT_APP_PARTNERS_ENDPOINT',
  'REACT_APP_TEST_PARTNER_KEY',
  'REACT_APP_OPTIMIZELY_DATAFILE_URL',
  'REACT_APP_DD_CLIENT_TOKEN',
  'REACT_APP_DD_RUM_APP_ID',
  'REACT_APP_DD_SAMPLE_RATE',
  'REACT_APP_DD_REPLAY_SAMPLE_RATE',
  'REACT_APP_IP_INFO_ENDPOINT',
  'REACT_APP_ANALYTICS_ENDPOINT',
  'REACT_APP_ANALYTICS_API_KEY',
  'REACT_APP_ENABLED_CURRENCIES',
  'REACT_APP_SAPI_PROFILE_KEY'
] as const

const _settings = Symbol('settingsKeys')
type SettingsKey = typeof configKeys[number]

const Settings = function (
  this: Record<typeof _settings, Record<string, string>>
) {
  const configs = window._env || process.env
  this[_settings] = {}

  this[_settings] = pickAll(configKeys, configs)
  const get = (key: SettingsKey): string => this[_settings][key]

  return {get}
}

// @ts-expect-error we have to fix general approach with Settings
export default new Settings() as {
  get: (key: SettingsKey) => string
}
