import {LogLevel, logToDataDog} from './datadogLogger'

export enum PartnersServiceStatus {
  start = 'Start',
  done = 'Done',
  error = 'Error'
}

export enum PartnersServiceEndpointNames {
  SearchHotel = 'SearchHotel'
}

type Metadata = Record<string, unknown>

const logPartnersService = (
  endpoint: PartnersServiceEndpointNames,
  status: PartnersServiceStatus,
  metadata?: Metadata,
  level?: LogLevel
) => {
  logToDataDog({
    logName: `Services.PartnersAPI.${endpoint}.${status}`,
    metadata: {metadata},
    level
  })
}

export default logPartnersService
