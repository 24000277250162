import {getUrlParams} from 'utils/urlParams'

import {createDataDogLogger} from '@daedalus/core/src/analytics/components/DataDogClient'

export type LogLevel = 'debug' | 'info' | 'warn' | 'error'

const {outputToConsole = false} = getUrlParams() as Record<string, unknown>

let loggerInstance: ReturnType<typeof createDataDogLogger>

const getBoFHLoggerInstance = () => {
  if (loggerInstance) return loggerInstance

  // eslint-disable-next-line fp/no-mutation
  loggerInstance = createDataDogLogger('bofh')
  return loggerInstance
}

export const logToDataDog = ({
  logName,
  metadata = undefined,
  level = 'info',
  outputToConsoleLocal = false
}: {
  logName: string
  metadata?: Record<string, unknown>
  level?: LogLevel
  outputToConsoleLocal?: boolean
}) => {
  const augmentedMetadata = {
    ...metadata,
    request_id: metadata?.request_id
  }

  try {
    const logger = getBoFHLoggerInstance()
    logger.log(`Partners.${logName}`, augmentedMetadata, level)

    if (outputToConsoleLocal || outputToConsole)
      console.log(`[DataDog] ${logName}`, metadata)
  } catch (error) {
    console.error('Failed to log on Datadog for the BoFH context')
    console.log(error)
  }
}
