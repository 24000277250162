import {MiddlewareType} from 'middlewares'
import {getEventProperties} from 'modules/analytics/trackEventHelpers'
import {logToDataDog} from 'services/datadogLogger'

import {
  TRACK_EXPERIMENT_SERVED,
  trackEvent
} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  TrackEventPayload
} from '@daedalus/core/src/analytics/types/Events'
import AnalyticsUtils from '@daedalus/core/src/analytics/utils'
import {createDataPipelineEventName} from '@daedalus/core/src/analytics/utils/trackEventHelpers'

export const middleware: MiddlewareType = store => next => async action => {
  const {dispatch} = store

  if (!AnalyticsUtils.analyticsPlatformEnabled()) return next(action)

  switch (action.type) {
    case trackEvent.type: {
      const payload = action.payload as TrackEventPayload
      const analyticsEventName = createDataPipelineEventName(payload)
      const eventProperties = getEventProperties(payload)

      AnalyticsUtils.trackEvent(analyticsEventName, eventProperties)

      logToDataDog({
        logName: eventProperties.name, // Use more descriptive full name for DD so that it's easier to navigate the logs
        // @ts-expect-error Not sure how to type this
        metadata: eventProperties,
        level: eventProperties.action === Action.Errored ? 'error' : 'info'
      })

      break
    }

    case TRACK_EXPERIMENT_SERVED: {
      const {experimentId, variationId, isActive} = action

      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Experiment,
          action: Action.Served,
          analyticsContext: {
            [AnalyticsContext.ExperimentContext]: {
              experimentId,
              variationId,
              isActive
            }
          }
        })
      )
      break
    }

    default:
      break
  }

  return next(action)
}

export default middleware
