import React, {forwardRef, ReactNode} from 'react'
import {css, cx} from '@linaria/core'
import {styled} from '@linaria/react'
import {SizesType} from 'types/Sizes'
import {CSSDescriptionType} from 'types/Theme'

import {hexToRGBAString} from '@daedalus/core/src/utils/css'

import {cssTheme} from '../../../../themes'
import {CSSThemeProvider} from '../../../../themes/CSSThemeProvider'
import {linariaMq} from '../../../../utils/breakpoints'
import {getLinariaClassName} from '../../../../utils/getLinariaClassName'
import {LoadingBullets} from '../../Button/LoadingBullets'

const BUTTON_VARIANTS = [
  'primary',
  'secondary',
  'special',
  'quiet',
  'transparent',
  'inverse',
  'danger',
  // added as part of b4f4f3cc-website-restyle-v3
  'primaryGradient',
  'secondaryGradient'
] as const

type ButtonVariantTuple = typeof BUTTON_VARIANTS

export type Variant = ButtonVariantTuple[number]

export type ButtonSize = Exclude<SizesType, 'xs' | 'xxl'>

export interface Props {
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Button content */
  children?: React.ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Button style */
  variant?: Variant
  /** Whether the button is in the loading state */
  loading?: boolean
  /** The button click handler */
  onClick?: (e: React.SyntheticEvent) => void
  /** The button size */
  size?: ButtonSize
  /** The HTML button type */
  type?: 'submit' | 'reset' | 'button' | undefined
  /** Whether the button full width of parent */
  fullWidth?: boolean
  /** Whether the button is  disabled */
  disabled?: boolean
  /** Whether the button is  rounded */
  isRounded?: boolean
  /** An Icon to display on the left side of the input for LTR languages. Inverted on RTL */
  iconStart?: ReactNode
  /** An Icon to display on the right side of the input for LTR languages. Inverted on RTL */
  iconEnd?: ReactNode
  /** To add drop shadow styling */
  isFloating?: boolean
  /** Use larger touch area */
  hasTouchArea?: boolean
  form?: string
  /** Optional aria-label for screen-readers, used for icon-only buttons */
  ariaLabel?: string
  /** Optional style object */
  style?: React.CSSProperties
}

interface ButtonSizeMap {
  size: number
  sizeWithTouchArea: number
  basePadding: number
  horizontalPadding?: number
  typography: CSSDescriptionType
}

export const buttonStyleVariants = [...BUTTON_VARIANTS, 'disabled']

export const buttonSizesMap: Record<ButtonSize, ButtonSizeMap> = {
  sm: {
    size: 26,
    sizeWithTouchArea: 44,
    basePadding: 4,
    typography: cssTheme.typography.text.labelXS
  },
  md: {
    size: 34,
    sizeWithTouchArea: 44,
    basePadding: 6,
    typography: cssTheme.typography.text.labelS
  },
  lg: {
    size: 42,
    sizeWithTouchArea: 44,
    basePadding: 8,
    typography: cssTheme.typography.text.labelM
  },
  xl: {
    size: 48,
    sizeWithTouchArea: 48,
    basePadding: 8,
    horizontalPadding: 16,
    typography: cssTheme.typography.text.labelM
  }
}

const getButtonLayoutStyles = (size: ButtonSize) => `
  &--size-${size} {
    min-height: ${buttonSizesMap[size].size}px;
    min-width: ${buttonSizesMap[size].size}px;
    &.hasTouchArea {
      min-height: ${buttonSizesMap[size].sizeWithTouchArea}px;
      min-width: ${buttonSizesMap[size].sizeWithTouchArea}px;
    }
  }
`

const getContentLayoutStyles = (size: ButtonSize) => {
  return `
    &--size-${size} {
      min-height: ${buttonSizesMap[size].size}px;
      min-width: ${buttonSizesMap[size].size}px;
      padding: ${
        buttonSizesMap[size].horizontalPadding
          ? `${buttonSizesMap[size].basePadding}px ${buttonSizesMap[size].horizontalPadding}px`
          : `${buttonSizesMap[size].basePadding}px`
      };
      font-size: ${buttonSizesMap[size].typography.fontSize};
      line-height: ${buttonSizesMap[size].typography.lineHeight};
      font-weight: ${buttonSizesMap[size].typography.fontWeight};
      letter-spacing: ${buttonSizesMap[size].typography.letterSpacing};
      font-family: ${buttonSizesMap[size].typography.fontFamily};
      [class*="--variant-primaryGradient"] & {
        padding: ${
          buttonSizesMap[size].horizontalPadding
            ? `${buttonSizesMap[size].basePadding + 1.5}px ${buttonSizesMap[size].horizontalPadding + 1.5}px`
            : `${buttonSizesMap[size].basePadding + 1.5}px`
        }
      }
    }
  `
}

export const StyledButtonContent = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${cssTheme.layout.radius.lg};

  ${Object.keys(buttonSizesMap)
    .map(size => getContentLayoutStyles(size as ButtonSize))
    .join('')}
  &.rounded {
    border-radius: ${cssTheme.layout.radius.rounded};
  }

  &.hasFloatingShadow {
    box-shadow: ${cssTheme.shadows.floating};
  }
`

const getButtonColorsStyles = (variant: Variant) => `
  &--variant-${variant} {
    .${StyledButtonContent} {
      color: ${cssTheme.colors.button[variant].content};
      background-color: ${cssTheme.colors.button[variant].background};
      border: 1.5px solid ${cssTheme.colors.button[variant].border};
      ${variant === 'inverse' ? `box-shadow: ${cssTheme.shadows.floating};` : ''}
      ${variant === 'inverse' ? 'opacity: 0.9;' : ''}
    }
    ${linariaMq.desktopXs} {
      &:hover,
      &:focus {
        .${StyledButtonContent} {
          background-color: ${
            variant === 'transparent'
              ? hexToRGBAString(
                  cssTheme.colors.button[variant].hover.background,
                  0.2
                )
              : cssTheme.colors.button[variant].hover.background
          };
          border-color: ${cssTheme.colors.button[variant].hover.border};
        }
      }
    }
    &:active {
      .${StyledButtonContent} {
        background-color: ${
          variant === 'transparent'
            ? hexToRGBAString(
                cssTheme.colors.button[variant].active.background,
                0.1
              )
            : cssTheme.colors.button[variant].active.background
        };
        border-color: ${cssTheme.colors.button[variant].active.border};
        transform: scale(0.925);
      }
    }
  }
`

const getGradientButtonColorsStyles = (variant: Variant) => `
  &--variant-${variant} {
    .${StyledButtonContent} {
      color: ${cssTheme.colors.button[variant].content};
      position: relative;
      background-image: ${cssTheme.colors.button[variant].background};
      z-index: 1;
      &:before {
        position: absolute;
        content: '';
        top:  0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: ${cssTheme.colors.button[variant].hover.background};
        z-index: -1;
        transition: opacity 0.3s linear;
        transform: translateZ(0); /* Fix for transition flickering in Safari */
        opacity: 0;
        border-radius: inherit;
      }
    }
    &:hover {
      .${StyledButtonContent} {
        &:before {
          opacity: 1;
        }
      }
    }
    &:active {
      .${StyledButtonContent} {
        transform: scale(0.925);
      }
    }
  }

`

// added as part of b4f4f3cc-website-restyle-v3
const getGradientBorderButtonColorsStyles = (variant: Variant) => `
  &--variant-${variant} {
    z-index: 0;
    position: relative;
    min-height: inherit;
    .${StyledButtonContent} {
      color: ${cssTheme.colors.button[variant].content};
      background-color: ${cssTheme.colors.button[variant].background};
      border: 1.5px solid transparent;
      background-clip: padding-box;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${cssTheme.colors.button[variant].border};
        border-radius: inherit;
        z-index: -1;
      }
    }
    ${linariaMq.desktopXs} {
      &:hover,
      &:focus-visible {
        .${StyledButtonContent} {
          background-color: ${cssTheme.colors.button[variant].hover.background};
        }
      }
    }
    &:active {
      transform: scale(0.925);
      .${StyledButtonContent} {
        background-color: ${cssTheme.colors.button[variant].active.background};
      }
    }
  }
`

const StyledButton = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  user-select: none;
  outline: none;
  position: relative;
  text-decoration: none;
  background: none;
  color: inherit;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  ${Object.keys(buttonSizesMap)
    .map(size => getButtonLayoutStyles(size as ButtonSize))
    .join('')}

  ${buttonStyleVariants
    .map(variant => {
      switch (variant) {
        case 'primaryGradient':
          return getGradientButtonColorsStyles(variant as Variant)
        case 'secondaryGradient':
          return getGradientBorderButtonColorsStyles(variant as Variant)
        default:
          return getButtonColorsStyles(variant as Variant)
      }
    })
    .join('')}

  &.fullWidth {
    width: 100%;
    .${StyledButtonContent} {
      flex-grow: 1;
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LabelWrapper = styled.div<{size: ButtonSize}>`
  padding: ${({size}) => `0 ${buttonSizesMap[size].basePadding}px`};
`

export const NewStyleButton = forwardRef(
  (
    {
      variant = 'primary',
      size = 'lg',
      loading = false,
      disabled = false,
      fullWidth = false,
      isRounded = false,
      className = '',
      dataId = '',
      type = 'button',
      onClick,
      children,
      iconStart,
      iconEnd,
      isFloating = false,
      hasTouchArea = false,
      form,
      ariaLabel,
      style
    }: Props,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const disabledState = loading || disabled

    return (
      <CSSThemeProvider noWrapper themeName="newvio">
        <StyledButton
          ref={ref}
          data-id={dataId}
          className={cx(
            `${getLinariaClassName(StyledButton)}--size-${size}`,
            `${getLinariaClassName(StyledButton)}--variant-${disabled ? 'disabled' : variant}`,
            fullWidth && 'fullWidth',
            hasTouchArea && 'hasTouchArea',
            className
          )}
          type={type}
          disabled={disabledState}
          onClick={onClick}
          form={form}
          aria-label={ariaLabel}
          style={style}
        >
          <div
            className={cx(
              StyledButtonContent,
              `${StyledButtonContent}--size-${size}`,
              isRounded && 'rounded',
              isFloating && !disabled && 'hasFloatingShadow'
            )}
          >
            {loading ? (
              <LoadingBullets size={size} />
            ) : (
              <>
                {iconStart && <IconWrapper>{iconStart}</IconWrapper>}

                {children && (
                  <LabelWrapper size={size}>{children}</LabelWrapper>
                )}

                {iconEnd && <IconWrapper>{iconEnd}</IconWrapper>}
              </>
            )}
          </div>
        </StyledButton>
      </CSSThemeProvider>
    )
  }
)

// eslint-disable-next-line fp/no-mutation
NewStyleButton.displayName = 'NewStyleButton'
