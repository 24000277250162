import {configureStore} from '@reduxjs/toolkit'
import appMiddlewares from 'middlewares'
import meta from 'modules/meta/slice'
import {combineReducers} from 'redux'
import {partnersApi} from 'services/partnersApi'

import experiments from '@daedalus/core/src/experiments/modules/slice'

const middlewares = appMiddlewares

export const createReducer = () =>
  combineReducers({
    [partnersApi.reducerPath]: partnersApi.reducer,
    experiments,
    meta
  })

type CreateReducer = ReturnType<typeof createReducer>
export type RootState = ReturnType<CreateReducer>

const initializeStore = (initialState: Partial<RootState>) =>
  configureStore({
    reducer: createReducer(),
    preloadedState: {
      ...initialState
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(...middlewares)
  })

export default initializeStore
